<template>
    <div class="result">
             <Header/>
            <div class="banner">
                <div id="qrCode">
                <div class="content">
                    <div class="title">{{resultlist.title}}</div>
                    <div class="userbox">
                        <div class="user">
                            <img v-if="user.headImg" :src="user.headImg" alt="">
                            <img v-else src="../assets/img/user.png" alt="">
                            <div class="name">
                                <div class="username">{{user.name}}</div>
                                <span>工号 {{user.username}}</span>
                            </div>
                        </div>
                        <div class="result">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-icon_29"></use>
                            </svg>
                            <span>{{resultlist.score}}分</span>
                            <div>({{resultlist.totalScore}}分)</div>
                        </div>
                    </div>
                    <div class="time">
                        <span>考试时间：</span>
                        <p>{{resultlist.startTime}} - {{resultlist.endTime}}</p>
                    </div>
                    <div class="time">
                        <span>总分：</span>
                        <p>{{resultlist.totalScore}}分</p>
                        <span>题数：</span>
                        <p>{{resultlist.questionsNumber}}</p>
                        <span>答题时间：</span>
                        <p>{{resultlist.answerTime}}分钟</p>
                    </div>
                    <div class="time">
                        <span>作答时间：</span>
                        <p>{{resultlist.testTime}}</p>
                    </div>

                    <div class="time">
                        <span>工号：</span>
                        <p>{{user.username}}</p>
                    </div>
                    <div class="time">
                        <span>性别：</span>
                        <p>{{gender}}</p>
                    </div>
                    <div class="time">
                        <span>身份证号码：</span>
                        <p>{{user.idNumber||'-'}}</p>
                    </div>
                    <div class="time">
                        <span>手机号码：</span>
                        <p>{{user.phone||'-'}}</p>
                    </div>
                     <div class="time">
                        <span>职务名称：</span>
                        <p>{{name||'-'}}</p>
                    </div>
                </div>
            </div>

            </div>
            <div class="btn">
                <!--<button v-if="resultlist.isComp!==1" class="again" @click="handelagin">重新考试</button>-->
                <button @click="handelSee">查看解析</button>
                <!--<button @click="handelSee">返回上层</button>-->
                <button v-print="printOptions">打印成绩单</button>
            </div>
            <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from '../components/Footer'
import {GetTextResults} from '../api/text'
import {GetDict} from '../api/list'
    export default {
        data() {
            return {
                resultlist:'',
                user:'',
                name: 0,
                gender:'',
                printOptions:{
                   id:'#qrCode',
                   popTitle:'',
                    extraHead:'<meta name="viewport" \n' +
                        '    content="width=device-width,\n' +
                        '   initial-scale=1.0,\n' +
                        '   user-scalable=no,\n' +
                        '   minimum-scale=1.0\n' +
                        '   maximum-scale=1.0"\n' +
                        '/>',
                }
            }
        },
        created () {
            // history.pushState(null, null, location.href);
            // window.addEventListener("popstate", function () {
            //     history.pushState(null, null, location.href);
            // });
            this.user = JSON.parse(window.sessionStorage.getItem('user'))
            if(this.user.gender===1){
                this.gender="男"
            }else if(this.user.gender===2){
                this.gender="女"
            }else if(this.user.gender===3){
                this.gender="保密"
            }
            GetDict('post_type').then(res=>{
                    res.data.forEach(i=>{
                        if(i.value==this.user.postCode){
                            this.name=i.label
                        }
                    })
                })
            GetTextResults(this.$route.query.id,this.$route.query.rounds==null?'':this.$route.query.rounds).then(res=>{
                this.resultlist = res.data
                this.printOptions.popTitle=this.resultlist.title
            })
        },
        components: {
            Header,
            Footer
        },
       methods: {
           handelSee() {
               this.$router.push({
                    path:'/examanalysis',
                    name:'Examanalysis',
                    query:{
                        id : this.resultlist.paperId,
                        score : this.resultlist.score,
                        rounds:this.$route.query.rounds
                        }
               })
           },
           handelagin(){
               // this.$fullscreen.enter()
                this.$router.push({
                    path:'/exam',
                    name:'Exam',
                    query:{
                        id:this.resultlist.paperId
                    }
                })
           },
           handelbreak(i){
                this.$router.push('/'+i)
           },
           handelpush(){
               this.$router.push('home')
           },
           printBtn(){
                printDeliveryOrder({ distributionNumberList : this.distributionNumberList}).then(response => {//方法传参(有的不需要!不需要的直接写下面)
                    var newWin = window.open(""); //新打开一个空窗口
                    var toPrint = document.getElementById("qrCode"); //将要被打印的东西
                    newWin.document.write(toPrint.outerHTML); //将打印内容添加进新的窗口
                    newWin.document.close(); //在IE浏览器中使用必须添加这一句
                    newWin.focus(); //在IE浏览器中使用必须添加这一句
                    setTimeout(function() {
                        newWin.print(); //打印
                        newWin.close(); //关闭窗口
                    }, 100);
                })
                }
       },

    }
</script>

<style scoped>
@import url(../assets/css/Result.css);
 @import url("../assets/css/Result_print.css") print;
</style>
