import request from '../utils/request'


export const GetTextList=(categoryId)=>{      //综合试卷
    return request.get(`/api/testPaper/comExam?accountId=${categoryId}`)
}
export const GetTryList=()=>{      //模拟试卷
    return request.get(`/api/subject/getSubjectByPaperId`)
}
export const PostTextList=(textlist)=>{         //提交试卷
    return request.post(`/api/subject/calculation`,textlist)
}
export const GetTextResults=(id,rounds)=>{         //获取成绩
    return request.get(`/api/racord/record?paperId=${id}&rounds=${rounds}`)
}

export const GetTextAnalysis=(id,rounds,viewWrong)=>{         //题目解析
    return request.get(`/api/testPaper/getSubject?paperId=${id}&rounds=${rounds}&viewWrong=${viewWrong}`)
}

//获取考试成绩数据统计testType(0模拟,1综合)
export const GetExamination =(testType,categoryId)=>{

    return request.get(`/api/racord/getLmitateExamination?testType=${testType}&accountId=${categoryId}`)
}
//获取考试成绩列表testType(0模拟,1综合)
export const GetExaminationList =(limit,page,testType,categoryId)=>{

    return request.get(`/api/racord/getExaminationInfoList?limit=${limit}&page=${page}&testType=${testType}&accountId=${categoryId}`)
}

//获取随机练习题
export const GetRandomQuestPage=(categoryId,page,pageSize)=>{

    return request.get(`/api/subject/randomStPage?accountId=${categoryId}&page=${page}&limit=${pageSize}`)

  }

//获取章节下的题目
export const GetChapterQuestList=(chapterId,page,pageSize)=>{

    return request.get(`/api/category/getZjTmPage?zjId=${chapterId}&page=${page}&limit=${pageSize}`)
}

//我的错题本
export const GetMyErrorQuestList=(categoryId,page,pageSize)=>{

    return request.get(`/api/mistakeList/wrongQuestionPage?accountId=${categoryId}&page=${page}&limit=${pageSize}`)
}
//我的收藏
export const GetMyCollectQuestList=(categoryId,page,pageSize)=>{

    return request.get(`/api/collectQuestions/getCollectPage?accountId=${categoryId}&page=${page}&limit=${pageSize}`)
}

//获取练习题列表
export const GetPracticeQuestionList=(url,categoryId,page,pageSize)=>{

   return request.get(`${url}?accountId=${categoryId}&page=${page}&limit=${pageSize}`)
}

//提交答案
export const SubmitAnswer=(data)=>{
    return request.post('/api/AnswerJudgment/check',data)
}

//收藏题
export const CollectQuest=(data)=>{

    return request.post('/api/collectQuestions/add',data)
}

//判断是否收藏某题
export const GetQuestCollectById=(subjectId)=>{

    return request.get(`/api/collectQuestions/checkIscollect?subjectId=${subjectId}`)
}

//统计
export const GetStatisticsCount=(categoryId)=>{

    return request.get(`/api/statistics/getStatistics?accountId=${categoryId}`)
};

//统计-练习记录
export const GetPracticeRecords=(limit,page,categoryId)=>{

    return request.get(`/api/statistics/getStatisticsDetails?limit=${limit}&page=${page}&accountId=${categoryId}`)

}

//保存练习记录
export const SavePracticeRecord=(data)=>{

    return request.post('/api/countNumber/insertCount',data)
}

//图片上传
export const UploadImage=(data)=>{

    return request.post('/upload/imgUpload',data)
}

//纠错提交
export const SaveQuestionFeedback=(data)=>{

    return request.post('/api/correction/insertCorrection',data)
}

//保存笔记
export const SaveQuestionNote=(data)=>{

    return request.post('/api/note/insertNote',data)
}